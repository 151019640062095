import './DepartmentNavigationLinks.scss';

import React from 'react';
import classNames from 'classnames';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { useCurrentIssue } from 'context/IssueContext';

interface Props {
  departmentSlug: string;
}

const DepartmentNavigationLinks: React.FC<Props> = (props) => {
  const { departmentSlug } = props;

  const data = useStaticQuery<{
    allDepartmentsYaml: {
      departments: {
        name: string;
        slug: string;
        color: string;
      }[];
    };
  }>(graphql`
    {
      allDepartmentsYaml {
        departments: nodes {
          name
          slug
          color
        }
      }
    }
  `);

  const { issue } = useCurrentIssue();
  const departments = data.allDepartmentsYaml.departments.filter((department) =>
    issue.departments.includes(department.slug)
  );

  const currentDepartmentIndex = departments.findIndex(
    (d) => d.slug === departmentSlug
  );
  const previousDepartment = departments[currentDepartmentIndex - 1];
  const nextDepartment = departments[currentDepartmentIndex + 1];

  return (
    <div
      className={classNames('DepartmentNavigationLinks', {
        'DepartmentNavigationLinks--onlyNext':
          !previousDepartment && nextDepartment
      })}
    >
      {previousDepartment && (
        <Link
          to={`/${issue.slug}/${previousDepartment.slug}`}
          className={`DepartmentNavigationLinks-link DepartmentNavigationLinks-link--${previousDepartment.color}`}
        >
          <svg
            className="DepartmentNavigationLinks-link-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 32"
          >
            <path
              style={{ fill: 'none', stroke: '#000', strokeWidth: 4 }}
              d="M17 3 L3 16 L17 29"
            />
          </svg>

          <div className="DepartmentNavigationLinks-link-text">
            {previousDepartment.name}
          </div>
        </Link>
      )}

      {nextDepartment && (
        <Link
          to={`/${issue.slug}/${nextDepartment.slug}`}
          className={`DepartmentNavigationLinks-link DepartmentNavigationLinks-link--${nextDepartment.color}`}
        >
          <div className="DepartmentNavigationLinks-link-text">
            {nextDepartment.name}
          </div>

          <svg
            className="DepartmentNavigationLinks-link-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 32"
          >
            <path
              style={{ fill: 'none', stroke: '#000', strokeWidth: 4 }}
              d="M3 3 L17 16 L3 29"
            />
          </svg>
        </Link>
      )}
    </div>
  );
};

export default DepartmentNavigationLinks;
