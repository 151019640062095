import './Quote.scss';

import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Markdown from 'components/core/Markdown';

export interface Props {
  quote: string;
  author: string;
  authorTagline: string | null;
  date: string;
  textStyle: 'sans' | 'sans-bold' | 'serif' | 'display';
  authorImage: {
    sizes: {
      small: {
        width: number;
        height: number;
        src: string;
        srcSet: string;
      };
    };
  } | null;
}

const OverheardQuote: React.FC<Props> = (props) => {
  const { authorImage, quote, author, authorTagline, date, textStyle } = props;

  return (
    <div className="OverheardQuote">
      <div className={`OverheardQuote-content--${textStyle}`}>
        &ldquo;{quote.trim()}&rdquo;
      </div>
      <div className="OverheardQuote-author">
        {authorImage && (
          <Img
            fixed={authorImage.sizes.small}
            className="OverheardQuote-image"
            // "shouldn't" be necessary, but fixes a bug where these images
            // don't display in Safari when in a container with columns.
            style={{ overflow: 'visible' }}
          />
        )}
        <div>
          <strong>{author}</strong>,
          {authorTagline && (
            <>
              {' '}
              <Markdown inline>{`${authorTagline.trim()},`}</Markdown>
            </>
          )}
          <em> {date}</em>
        </div>
      </div>
    </div>
  );
};

export const OverheardQuoteFragment = graphql`
  fragment OverheardQuote on QuotesYaml {
    quote
    author
    authorTagline
    date
    authorImage {
      sizes: childImageSharp {
        small: fixed(height: 60, width: 60) {
          width
          height
          src
          srcSet
        }
      }
    }
    textStyle
  }
`;

export default OverheardQuote;
