import './Quotes.scss';

import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';

import Quote, { Props as OverheardQuoteProps } from './Quote';

export interface Props {
  quotes: {
    nodes: {
      quotes: OverheardQuoteProps[];
    }[];
  };
}

const Quotes: React.FC<Props> = (props) => {
  const { quotes } = props.quotes.nodes[0];

  return (
    <div className="OverheardQuotes">
      <Core.Flag>Quotable</Core.Flag>
      <div className="OverheardQuotes-content">
        {quotes.map((quote, index) => (
          <Quote key={index} {...quote} />
        ))}
      </div>
    </div>
  );
};

export const OverheardQuotesFragment = graphql`
  fragment OverheardQuotes on FileConnection {
    nodes {
      quotes: childrenQuotesYaml {
        ...OverheardQuote
      }
    }
  }
`;

export default Quotes;
