import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Quotes, { Props as QuotesProps } from 'components/overheard/Quotes';

interface Props {
  data: QuotesProps & {
    image: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { quotes, image } = props.data;

  return (
    <Layout departmentSlug="overheard" title="Overheard">
      <Core.ContentWrapper>
        <Core.ContentSection id="campus-visitor">
          <Core.DepartmentLabel>Overheard</Core.DepartmentLabel>
          <Core.Flag>Campus Visitor</Core.Flag>
          <Core.PrimaryHeading>Competing in Generosity</Core.PrimaryHeading>

          <p>
            <Core.LeadIn>Bill Gates</Core.LeadIn> hopes that one day, countries
            will compete to be the most generous.
          </p>
          <p>
            For example, when he looks at China’s investments in health and
            development projects in parts of Africa, he doesn’t just see a
            global adversary with whom the U.S. is teetering on the edge of a
            trade war—he sees a country providing support where it’s needed
            most, he said during a talk last June at SAIS.
          </p>
          <p>
            Years ago, Gates said, China’s economy wouldn’t have sustained such
            economic investment in other countries. Now it’s a major player on
            the international stage.
          </p>
          <p>
            “Hopefully the U.S. will be more generous as China has become more
            generous,” he said. “I hope countries will compete in generosity
            when it comes to the developing world.”
          </p>
        </Core.ContentSection>

        <Core.FullWidthImage image={image} alt="Bill Gates" />

        <Core.ContentSection>
          <p>
            The billionaire founder of Microsoft visited SAIS to discuss
            philanthropy and health leadership around the world. A business
            magnate and humanitarian, he co-founded with his wife the Bill &
            Melinda Gates Foundation in 2000 to enhance health care and reduce
            extreme poverty around the world, and to increase access to
            education and technology in the U.S.
          </p>
          <p>
            Gates planned his visit to coincide with an invitation from Congress
            to testify about U.S. investment in foreign aid, which Gates
            considers a crucial part of improving health around the world.
          </p>
          <p>
            During his talk—which was moderated by Cinnamon Dornsife, associate
            practitioner-in-residence and senior advisor of international
            development at SAIS, and Jeremy Shiffman, a recently appointed Johns
            Hopkins Bloomberg Distinguished Professor of global health
            policy—Gates discussed the importance of U.S. investment in the
            developing world, especially in terms of health.
          </p>
          <Core.SignOff>Saralyn Cruickshank, Johns Hopkins Hub</Core.SignOff>
          <Core.Share id="campus-visitor" />
        </Core.ContentSection>

        <Quotes quotes={quotes} />

        <Core.MoreResources>
          To see all SAIS Campus events, visit{' '}
          <a
            href="http://events.sais-jhu.edu/#the-recap"
            target="_blank"
            rel="noopener noreferrer"
          >
            events.sais-jhu.edu/#the-recap
          </a>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    quotes: allFile(
      filter: { relativePath: { regex: $directory }, name: { eq: "quotes" } }
    ) {
      ...OverheardQuotes
    }
    image: file(
      relativePath: { regex: $directory }
      name: { eq: "overheard-bill-gates" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default DepartmentContent;
