import React from 'react';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import DepartmentNavigationLinks from 'components/DepartmentNavigationLinks';

interface Props {
  children: React.ReactNode;
  title: string;
  departmentSlug: string;
}

const DepartmentLayout: React.FC<Props> = (props) => {
  const { children, departmentSlug, title } = props;

  return (
    <Layout title={title}>
      {children}
      <Core.ContentWrapper>
        <DepartmentNavigationLinks departmentSlug={departmentSlug} />
      </Core.ContentWrapper>
    </Layout>
  );
};

export default DepartmentLayout;
